<template>
    <div class="display">
        <p class="display-title">基本信息</p>
        <div class="display-report">
            <p class="display-report-title">报告抬头：</p>
            <p class="display-report-content">{{ this.reportTitle }}</p>
        </div>
        <div class="display-subtitle">
            <p class="display-subtitle-title">二级标题：</p>
            <p class="display-subtitle-content">{{ this.reportSubTitle }}</p>
        </div>
        <div class="display-sign">
            <p class="display-sign-title">默认报告签名：</p>
            <el-switch class="display-sign-switch" v-model="sopReportSign" active-color="#6DA4D8" inactive-color="#DCDFE6"
                disabled>
            </el-switch>
            <p class="display-sign-name">{{ this.sopReportSignValue }}</p>
        </div>
        <div class="display-date">
            <p class="display-date-title">
                默认签名日期：
            </p>
            <el-switch class="display-date-switch" v-model="sopReportSignDate" active-color="#6DA4D8" inactive-color="#DCDFE6" disabled>
            </el-switch>
          
        </div>
        <icon-unit
        class="display-middle-right"
        :iconType = "'noTitle'"
        iconKey="睡眠初筛报告Logo"
        :iconURL="reportLogo"
      ></icon-unit>
    </div>
</template>
  
<script>
import IconUnit from "@c/setting/organization-configuration/display/icon-unit.vue";
export default {
    components: {
        IconUnit,
    },
    props: {
        model: Object,

    },

    data() {
        return {
            sopReportSign: false,
            sopReportSignDate: false,
            sopReportSignValue: "",
        
            reportTitle:"",
            reportSubTitle:"",
            reportLogo:"",
        }
    },

    created() {
        this.initValue()
    },
    computed: {
    
    },

    methods:{
        initValue(){
             
            if (this.model.sopReportSignDate == null || this.model.sopReportSignDate == "0") {
                this.sopReportSignDate = false;
            
            } else {
                this.sopReportSignDate = true;
                
            }

            if (this.model.sopReportSign == null || this.model.sopReportSign == "") {
                this.sopReportSign = false;
                this.sopReportSignValue = "";
            } else {
                this.sopReportSign = true;
                this.sopReportSignValue = this.model.sopReportSign;
            }
            this.reportTitle = this.model.sopReportHeaders
            this.reportSubTitle = this.model.sopReportSubTitle
            this.reportLogo = this.model.sopReportLogo
        }
    },

    watch: {
        model(newValue) {
            
            if ( newValue.sopReportSignDate == "1") {
                this.sopReportSignDate = true;
        
            } else {
                this.sopReportSignDate = false;
       
            }

            if (newValue.sopReportSign == null || newValue.sopReportSign == "") {
                this.sopReportSign = false;
                this.sopReportSignValue = "";
            } else {
                this.sopReportSign = true;
                this.sopReportSignValue = newValue.sopReportSign
            }
            this.reportTitle = newValue.sopReportHeaders
            this.reportSubTitle = newValue.sopReportSubTitle
            this.reportLogo = newValue.sopReportLogo

        }
    }
};
</script>
  
<style lang="scss" scoped>
.display {
    background-color: white;
    width: 100%;
    position: relative;

    &-title {
        color: black;
        font-size: rem(16);
        font-weight: bold;
        height: rem(22);
        margin-top: rem(16);
    }

    &-report {
        &-title{
            width: rem(70);
        }
        display: flex;
        margin-top: rem(18);
        color: black;
        font-size: rem(14);
    }
    &-subtitle {
        &-title{
            width: rem(70);
        }
        display: flex;
        margin-top: rem(18);
        color: black;
        font-size: rem(14);
    }
    &-sign {
        margin-top: rem(20);
        display: flex;
        align-items: center;
        font-size: rem(14);
        color: black;


        &-title {
            width: rem(108);

        }

        &-switch {
            transform: scale(1.3);
            margin-left: rem(6);

        }

        &-name{
            margin-left: rem(26);
        }
    }

    &-date {
        margin-top: rem(20);
        display: flex;
        align-items: center;
        font-size: rem(14);
        color: black;


        &-title {
            width: rem(108);

        }

        &-switch {
            transform: scale(1.3);
            margin-left: rem(6);

        }
        &-date{
            margin-left: rem(26);
        }
    }
}
</style>